<template>
	<!-- eslint-disable max-lines-->
	<SiteScannerLayout
		:header-title="'Cookie Scanner' | useLabels"
		header-caption="Scan your website for cookies"
	>
		<template #content>
			<FilterBar
				:search-query.sync="siteScannerSearchQuery"
				search-query-label="Scan Name"
				hide-brand-filter
				@persistSearchQuery="changeSiteScannerSearchQuery"
				@clearFilters="clearFilters"
			>
				<template #after-filters>
					<TextField
						v-model="siteScannerDomainSearchQuery"
						label="Domain"
						@input="changeSiteScannerDomainSearchQuery($event)"
					/>
				</template>
				<template #action>
					<PrimaryActionButton
						v-if="siteScannerFullAccess"
						@click="scanSite"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Scan your domain
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Cookie Scans
				</template>
				<template #body>
					<DataTable
						:headers="singleScanTableHeaders"
						:items="singleScanData"
						sort-by="id"
						sort-desc
						:loading="isLoading"
						@click:row="viewReport"
					>
						<template #item.id="{item}">
							{{ item.id === 'No Number' ? null : item.id }}
						</template>
						<template #item.dateCompleted="{item}">
							{{ item.status === 'Ready' ? item.dateCompleted : null }}
						</template>
						<template
							#item.status="{item}"
						>
							<div
								v-if="item.status ==='Scanning'"
							>
								{{ item.status }}
								<v-progress-circular
									v-if="item.statusDetails && item.statusDetails.percentageComplete"
									:size="20"
									:value="item.statusDetails.percentageComplete"
									:rotate="270"
								/>
							</div>
							<div v-else>
								{{ item.status }}
							</div>
						</template>
						<template #item.action="{item}">
							<IconButton
								v-if="item.status === 'Ready' && userCanUseSiteScanner"
								tooltip-text="View Report"
								@click="viewReport(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="item.status.includes('Unsuccessful') && userCanUseSiteScanner"
								tooltip-text="View Scan Errors"
								@click.stop.prevent="viewErrorScanDetails(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="item.status === 'Scanning'"
								tooltip-text="View Scan Progress"
								@click="handleStatusClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="item.status === 'Ready' && userCanUseSiteScanner"
								tooltip-text="Download Report"
								@click.stop.prevent="downloadReport(item.scanGuid, downloadTypeEnum.PDF)"
							>
								mdi-pdf-box
							</IconButton>
							<IconButton
								v-if="item.status === 'Ready' && userCanUseSiteScanner && hasMultipleDomainEntries(item)"
								tooltip-text="Compare Scan Reports"
								@click.stop.prevent="handleCompareClick(item)"
							>
								mdi-swap-horizontal
							</IconButton>
							<IconButton
								v-if="item.isAggregate && item.status === 'Ready' && userCanUseSiteScanner"
								tooltip-text="Download Cookies"
								@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.COOKIE_REPORT)"
							>
								mdi-cookie
							</IconButton>
							<IconButton
								v-if="item.isAggregate && item.status === 'Ready' && userCanUseSiteScanner"
								tooltip-text="Download Advanced Trackers"
								@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.ADVANCED_TRACKERS)"
							>
								mdi-radar
							</IconButton>
							<IconButton
								v-if="item.isAggregate && item.status === 'Ready' && userCanUseSiteScanner"
								tooltip-text="Download Sub Resources"
								@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.SUB_RESOURCES)"
							>
								mdi-text
							</IconButton>
							<IconButton
								v-if="!item.isArchived && siteScannerFullAccess && item.status !== 'Scanning'"
								tooltip-text="Delete Cookie Scan"
								@click.stop.prevent="() => (cookieScanToBeRemoved = item)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<SectionCard>
				<template #title>
					Recurring Scans
				</template>
				<template #body>
					<v-expansion-panels
						v-if="groupedRecurringScans.length > 0"
						:value="0"
					>
						<v-expansion-panel
							v-for="(group, index) in groupedRecurringScans"
							:key="index"
						>
							<v-expansion-panel-header class="custom-header">
								<div class="heading-texts">
									<div class="heading-scan-name">
										<strong>
											{{ group[0].scanName }}
										</strong>
									</div>
									<div>
										{{ headingStatusText(group[0]) }}
									</div>
								</div>
								<template #actions>
									<IconButton
										v-if="userCanUseSiteScanner && group[0].statusId === 3"
										tooltip-text="Cancel Recurring Scan"
										@click.stop.prevent="() => (recurringScanToCancel = group.find(m => m.status === 'Scheduled'))"
									>
										mdi-cancel
									</IconButton>
									<v-icon>
										mdi-chevron-down
									</v-icon>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<DataTable
									:headers="recurringScanTableHeaders"
									:items="group"
									sort-by="id"
									sort-desc
									:loading="isLoading"
									@click:row="viewReport"
								>
									<template #item.dateCompleted="{item}">
										{{ item.status === 'Ready' ? item.dateCompleted : null }}
									</template>
									<template
										#item.status="{item}"
									>
										<div
											v-if="item.status ==='Scanning'"
										>
											{{ item.status }}
											<v-progress-circular
												v-if="item.statusDetails && item.statusDetails.percentageComplete"
												:size="20"
												:value="item.statusDetails.percentageComplete"
												:rotate="270"
											/>
										</div>
										<div v-else>
											{{ item.status }}
										</div>
									</template>
									<template #item.action="{item}">
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner"
											tooltip-text="View Report"
											@click="viewReport(item)"
										>
											mdi-eye
										</IconButton>
										<IconButton
											v-if="item.status.includes('Unsuccessful') && userCanUseSiteScanner"
											tooltip-text="View Scan Errors"
											@click.stop.prevent="viewErrorScanDetails(item)"
										>
											mdi-eye
										</IconButton>
										<IconButton
											v-if="item.status === 'Scanning'"
											tooltip-text="View Scan Progress"
											@click="handleStatusClick(item)"
										>
											mdi-eye
										</IconButton>
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner"
											tooltip-text="Download Report"
											@click.stop.prevent="downloadReport(item.scanGuid, downloadTypeEnum.PDF)"
										>
											mdi-pdf-box
										</IconButton>
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner && group.length > 1"
											tooltip-text="Compare Scan Reports"
											@click.stop.prevent="handleCompareClick(item)"
										>
											mdi-swap-horizontal
										</IconButton>
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner"
											tooltip-text="Download Cookies"
											@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.COOKIE_REPORT)"
										>
											mdi-cookie
										</IconButton>
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner"
											tooltip-text="Download Advanced Trackers"
											@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.ADVANCED_TRACKERS)"
										>
											mdi-radar
										</IconButton>
										<IconButton
											v-if="item.status === 'Ready' && userCanUseSiteScanner"
											tooltip-text="Download Sub Resources"
											@click.stop.prevent="downloadCsv(item.scanGuid, downloadTypeEnum.SUB_RESOURCES)"
										>
											mdi-text
										</IconButton>
										<IconButton
											v-if="!item.isArchived && siteScannerFullAccess && item.status !== 'Scanning' && item.status !== 'Scheduled' && item.status !== 'Cancelled'"
											tooltip-text="Delete Cookie Scan"
											@click.stop.prevent="() => (cookieScanToBeRemoved = item)"
										>
											mdi-trash-can
										</IconButton>
									</template>
								</DataTable>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<div
						v-else
						class="text-center"
					>
						There are currently no recurring scans scheduled
					</div>
				</template>
			</SectionCard>
			<ScanStatusModal
				v-if="scanStatusModalOpen"
				:scan-details="selectedScanStatus"
				@close="handleCloseStatusModal"
			/>
			<ConfirmDeleteModal
				v-if="cookieScanToBeRemoved"
				:entity-name="cookieScanToBeRemoved.scanName"
				entity-type="Cookie Scan"
				@close="cookieScanToBeRemoved = null"
				@delete="deleteCookieScan"
			/>
			<ConfirmDeleteModal
				v-if="recurringScanToCancel"
				:entity-name="recurringScanToCancel.scanName"
				entity-type="Recurring Cookie Scan"
				is-cancel
				@close="recurringScanToCancel = null"
				@delete="cancelRecurringScan"
			/>
			<CompareScansSelectModal
				v-if="compareScansSelectModalOpen"
				:selected-scan="selectedScanToCompare"
				:comparable-scans="comparableScans"
				@close="handleCloseCompareSelectModal"
				@compare="viewComparisonResults"
			/>
			<CompareScansResultsModal
				v-if="compareScansResultsModalOpen"
				:scan-to-compare-from="scanToCompareFrom"
				:scan-to-compare-to="scanToCompareTo"
				@close="handleCloseCompareResultsModal"
			/>
			<SiteScannerUnsuccessfulScanModal
				v-if="showUnsuccessfulScanModal"
				:selected-scan="selectedUnsuccessfulScan"
				@close="closeUnsuccessfulScanModal"
			/>
		</template>
	</SiteScannerLayout>
</template>
<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import SiteScannerLayout from './site-scanner-layout.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import downloadCSVMixin from '../../../../../../shared/mixins/download-csv.js'
import generatePDFMixin from '../../../../../../shared/mixins/generate-pdf.js'
import ScanStatusModal from '../../../../../../shared/components/scan-status-modal.vue'
import CompareScansSelectModal from './site-scanner-comparison-select-scans-modal.vue'
import CompareScansResultsModal from './site-scanner-comparison-results-modal.vue'
import SiteScannerUnsuccessfulScanModal from './site-scanner-unsuccessful-scan-modal.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { SITE_SCANNER_FULL_ACCESS, SITE_SCANNER_READ_ONLY } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { siteScannerVersion } from '../../../../../../shared/state/config-keys.js'
import { getSiteScans, downloadScanFile, deleteCookieScan, cancelRecurringScan } from '../../../../../../shared/utils/api/site-scanner.js'
import { downloadTypeEnum } from './download-type-enums.js'
import {
	changeSiteScannerSearchQuery,
	changeSiteScannerDomainSearchQuery,
	siteScannerSearchQuery,
	siteScannerDomainSearchQuery
} from '../../../../../../shared/state/site-scanner.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../../shared/utils/date-formatting.js'

export default {
	components: {
		SiteScannerLayout,
		SectionCard,
		FilterBar,
		TextField,
		DataTable,
		PrimaryActionButton,
		IconButton,
		ScanStatusModal,
		ConfirmDeleteModal,
		CompareScansSelectModal,
		CompareScansResultsModal,
		SiteScannerUnsuccessfulScanModal
	},
	mixins: [downloadCSVMixin, generatePDFMixin],
	setup () {
		return {
			downloadTypeEnum,
			siteScannerVersion,
			changeSiteScannerSearchQuery,
			changeSiteScannerDomainSearchQuery,
			siteScannerSearchQuery,
			siteScannerDomainSearchQuery
		}
	},
	data () {
		return {
			singleScans: [],
			recurringScans: [],
			lookupData: null,
			scanStatusModalOpen: false,
			selectedScanStatus: null,
			selectedScanToCompare: null,
			isLoading: false,
			cookieScanToBeRemoved: null,
			recurringScanToCancel: null,
			compareScansSelectModalOpen: false,
			compareScansResultsModalOpen: false,
			scanToCompareFrom: null,
			scanToCompareTo: null,
			showUnsuccessfulScanModal: false,
			selectedUnsuccessfulScan: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		singleScanTableHeaders () {
			let tableHeaders = [
				{ text: 'ID', value: 'id', width: '10%' },
				{ text: 'Domain', value: 'domain', width: '30%' },
				{ text: 'Date Requested', value: 'dateRequested', width: '15%' },
				{ text: 'Date Complete', value: 'dateCompleted', width: '15%' },
				{ text: 'Status', value: 'status', width: '15%' },
				{ text: 'Action', value: 'action', width: '15%', sortable: false }
			]

			if (this.siteScannerV2) {
				tableHeaders = [
					{ text: 'ID', value: 'id', width: '6%' },
					{ text: 'Scan Name', value: 'scanName', width: '23%' },
					{ text: 'Domain', value: 'domain', width: '25%' },
					{ text: 'Date Requested', value: 'dateRequested', width: '10%' },
					{ text: 'Date Complete', value: 'dateCompleted', width: '10%' },
					{ text: 'Status', value: 'status', width: '10%' },
					{ text: 'Action', value: 'action', width: '15%', sortable: false }
				]
			}
			return tableHeaders
		},
		recurringScanTableHeaders () {
			const tableHeaders = [
				{ text: 'ID', value: 'id', width: '6%' },
				{ text: 'Scan Name', value: 'scanName', width: '23%' },
				{ text: 'Domain', value: 'domain', width: '25%' },
				{ text: 'Date Requested', value: 'dateRequested', width: '10%' },
				{ text: 'Date Complete', value: 'dateCompleted', width: '10%' },
				{ text: 'Status', value: 'status', width: '10%' },
				{ text: 'Action', value: 'action', width: '15%' }
			]
			return tableHeaders
		},
		singleScanData () {
			if (this.singleScans != null) {
				return this.singleScans.filter(({ scanName, domain }) => {
					let check = true
					if (siteScannerSearchQuery.value) check = scanName.toLowerCase().includes(siteScannerSearchQuery.value.toLowerCase())
					if (siteScannerDomainSearchQuery.value) check = check && domain.toLowerCase().includes(siteScannerDomainSearchQuery.value.toLowerCase())
					return check
				})
			} else return this.singleScans
		},
		recurringScanData () {
			if (this.recurringScans != null) {
				return this.recurringScans.filter(({ scanName, domain }) => {
					let check = true
					if (siteScannerSearchQuery.value) check = scanName.toLowerCase().includes(siteScannerSearchQuery.value.toLowerCase())
					if (siteScannerDomainSearchQuery.value) check = check && domain.toLowerCase().includes(siteScannerDomainSearchQuery.value.toLowerCase())
					return check
				})
			} else return this.recurringScans
		},
		groupedRecurringScans () {
			const groups = {}
			this.recurringScanData.forEach(item => {
				const groupId = item.groupedSchedulesID
				if (groupId !== 0) {
					if (!groups[groupId]) {
						groups[groupId] = []
					}
					groups[groupId].push(item)
				}
			})
			this.recurringScanData.forEach(item => {
				const groupId = item.groupedSchedulesID
				if (groupId === 0) {
					groups[item.id] = [item]
				}
			})
			const groupsArray = Object.values(groups)
			groupsArray.forEach(group => {
				group.sort((a, b) => {
					return new Date(b.dateRequestedUnformatted) - new Date(a.dateRequestedUnformatted)
				})
			})
			return groupsArray
		},
		userCanUseSiteScanner () {
			return this.productAreaPermission(SITE_SCANNER_READ_ONLY)
		},
		siteScannerFullAccess () {
			return this.productAreaPermission(SITE_SCANNER_FULL_ACCESS)
		},
		siteScannerV2 () {
			const siteScannerVersion2 = 2
			return siteScannerVersion.value === siteScannerVersion2
		}
	},
	created () {
		this.getSiteScans()
	},
	methods: {
		async getSiteScans () {
			this.isLoading = true
			const { data: { singleScans, recurringScans, lookupData } } = await getSiteScans()
			this.isLoading = false
			this.singleScans = singleScans
			this.recurringScans = recurringScans
			this.lookupData = lookupData
		},
		scanSite () {
			this.$router.push({
				name: 'cookie-scanner-request-scan',
				params: {
					scanTypes: this.lookupData.scanTypes,
					maxUrls: this.lookupData.maxScanUrlResults,
					actionTypes: this.lookupData.actionTypes
				}
			})
		},
		viewReport (scan) {
			if (scan.status === 'Scanning') {
				this.handleStatusClick(scan)
				return
			}
			if (!this.userCanUseSiteScanner) return

			if (scan.status === 'Ready') {
				this.$router.push({
					name: 'cookie-scanner-report',
					query: {
						scanGuid: scan.scanGuid
					},
					params: {
						maxUrls: this.lookupData.maxScanUrlResults
					}
				})
			} else if (scan.status.includes('Unsuccessful')) {
				this.viewErrorScanDetails(scan)
			}
		},
		async downloadReport (scanGuid, downloadType) {
			const response = await downloadScanFile(scanGuid, downloadType, null)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]?.replace(/"/g, '')
			this.generatePdf(response.data, fileName)
		},
		async downloadCsv (scanGuid, downloadType) {
			const response = await downloadScanFile(scanGuid, downloadType, null)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
			this.generateCsv(response.data, fileName)
		},
		handleStatusClick (scan) {
			this.selectedScanStatus = {
				scanName: scan.scanName,
				...scan.statusDetails
			}
			this.scanStatusModalOpen = true
		},
		handleCloseStatusModal () {
			this.scanStatusModalOpen = false
			this.getSiteScans()
		},
		handleCompareClick (scan) {
			this.compareScansSelectModalOpen = true
			this.selectedScanToCompare = scan
			if (scan.groupedSchedulesID == null) {
				this.comparableScans = this.singleScans.filter(item => (item.domain === scan.domain || item.domain.endsWith('.' + scan.domain) || scan.domain.includes(item.domain)) && item.id !== scan.id)
			} else {
				this.comparableScans = this.recurringScans.filter(item => item.groupedSchedulesID === scan.groupedSchedulesID && item.id !== scan.id)
			}
		},
		handleCloseCompareSelectModal () {
			this.compareScansSelectModalOpen = false
			this.selectedScanToCompare = null
			this.comparableScans = null
		},
		viewComparisonResults (scanId) {
			const scanToCompareTo = this.recurringScans?.find(item => item.id === scanId) || this.singleScans.find(item => item.id === scanId)
			this.scanToCompareFrom = JSON.stringify(this.selectedScanToCompare)
			this.scanToCompareTo = JSON.stringify(scanToCompareTo)
			this.compareScansSelectModalOpen = false
			this.compareScansResultsModalOpen = true
		},
		handleCloseCompareResultsModal () {
			if (this.selectedScanToCompare != null) {
				this.compareScansSelectModalOpen = true
			}
			this.compareScansResultsModalOpen = false
			this.scanToCompareFrom = null
			this.scanToCompareTo = null
		},
		async deleteCookieScan () {
			await deleteCookieScan(this.cookieScanToBeRemoved.scanGuid)
				.then(() => {
					showSnackbar('Cookie Scan Deleted')
					this.cookieScanToBeRemoved = null
					this.getSiteScans()
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Deleting Cookie Scan',
						color: 'red'
					})
					this.cookieScanToBeRemoved = null
				})
		},
		async cancelRecurringScan () {
			await cancelRecurringScan(this.recurringScanToCancel.groupedSchedulesID)
				.then(() => {
					showSnackbar('Recurring Scan Cancelled')
					this.recurringScanToCancel = null
					this.getSiteScans()
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Cancelling Recurring Scan',
						color: 'red'
					})
					this.recurringScanToCancel = null
				})
		},
		viewErrorScanDetails (scan) {
			this.selectedUnsuccessfulScan = scan
			this.showUnsuccessfulScanModal = true
		},
		closeUnsuccessfulScanModal () {
			this.showUnsuccessfulScanModal = false
			this.selectedUnsuccessfulScan = null
		},
		headingStatusText (scheduleItem) {
			switch (scheduleItem.statusId) {
				case 3:
					return 'Next Scheduled Scan: ' + format(new Date(scheduleItem.dateRequestedUnformatted), DATE_TIME_FORMAT_NO_SECONDS)
				case 4:
					return 'Cancelled'
				default:
					return ''
			}
		},
		hasMultipleDomainEntries (item) {
			return this.singleScanData.filter(x => x.domain === item.domain && x.status === 'Ready').length > 1
		},
		clearFilters () {
			changeSiteScannerDomainSearchQuery(null)
		}
	}
}
</script>
<style lang="scss">
	.custom-header {
		transition: background-color 0.3s ease-in-out;
	}
	.custom-header.v-expansion-panel-header--active {
		background-color: #E0E0E0;
	}
	.custom-header:hover {
		background-color: #EEEEEE;
	}
	.heading-scan-name {
		width: 300px;
	}
	.heading-texts {
		display: flex;
	}
</style>
