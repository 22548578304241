<template>
	<Modal width="500px">
		<template #modal-title>
			Cookie Scan Progress - {{ scanDetails.scanName != null ? scanDetails.scanName : 'Unknown' }}
		</template>
		<template #modal-content>
			<v-row>
				<v-col class="modal-label">
					<span class="grey--text text--darken-2">
						Run Time:
					</span>
				</v-col>
				<v-col class="modal-value">
					<span class="font-weight-medium grey--text text--darken-2">
						{{ formatTime(scanDetails.timeRunning) }}
					</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="modal-label">
					<span class="grey--text text--darken-2">
						Estimated Time Remaining:
					</span>
				</v-col>
				<v-col class="modal-value">
					<span class="font-weight-medium grey--text text--darken-2">
						{{ formatTime(scanDetails.timeRemaining) }}
					</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="modal-label">
					<span class="grey--text text--darken-2">
						Number of URLs Scanned:
					</span>
				</v-col>
				<v-col class="modal-value">
					<span class="font-weight-medium grey--text text--darken-2">
						{{ scanDetails.scanName != null || (scanDetails.targetsComplete != 0 && scanDetails.targetsRemaining != 0) ? scanDetails.targetsComplete : 'Unknown' }}
					</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="modal-label">
					<span class="grey--text text--darken-2">
						Number of URLs Remaining:
					</span>
				</v-col>
				<v-col class="modal-value">
					<span class="font-weight-medium grey--text text--darken-2">
						{{ scanDetails.scanName != null || (scanDetails.targetsComplete != 0 && scanDetails.targetsRemaining != 0) ? scanDetails.targetsRemaining : 'Unknown' }}
					</span>
				</v-col>
			</v-row>
			<slot name="additional-content" />
		</template>

		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from './modal.vue'
import SecondaryActionButton from './secondary-action-button.vue'

export default {
	components: { SecondaryActionButton, Modal },
	props: {
		scanDetails: Object
	},
	methods: {
		formatTime (timeValue) {
			if (timeValue == null || timeValue === '') {
				return 'Unknown'
			}
			const parts = timeValue.split(' ')

			if (parts.length === 2) {
				const [value, unit] = parts
				let seconds = parseFloat(value)

				if (unit === 'minutes') {
					seconds *= 60
				} else if (unit === 'hours') {
					seconds *= 3600
				}

				const hours = Math.floor(seconds / 3600)
				const minutes = Math.floor((seconds % 3600) / 60)
				const remainingSeconds = Math.floor(seconds % 60)

				const HH = String(hours).padStart(2, '0')
				const MM = String(minutes).padStart(2, '0')
				const SS = String(remainingSeconds).padStart(2, '0')

				return `${HH}:${MM}:${SS}`
			}

			return null
		}
	}
}
</script>
