<template>
	<ViewLayout>
		<template #header-title>
			{{ headerTitle }}
		</template>
		<template #header-caption>
			{{ headerCaption }}
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout },
	props: {
		headerTitle: String,
		headerCaption: String
	}
}
</script>
