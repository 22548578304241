<template>
	<Modal width="750px">
		<template #modal-title>
			Cookie Scan Results Comparison - {{ selectedScan.scanName != null ? selectedScan.scanName : 'Unknown' }}
		</template>
		<template #modal-content>
			<v-row>
				<v-col
					class="modal-label"
					cols="3"
				>
					<span class="grey--text text--darken-2">
						Selected Scan:
					</span>
				</v-col>
				<v-col
					class="modal-value"
					cols="8"
				>
					<TextField
						:value="selectedScanLabel"
						label="Selected Scan"
						view-only
					/>
				</v-col>
				<v-col
					class="pt-4 mt-1"
					cols="1"
				>
					<v-tooltip
						slot="append"
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							The Cookie Scan on which the comparison will be based
						</span>
					</v-tooltip>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="3"
					class="modal-label"
				>
					<span class="grey--text text--darken-2">
						Compare with:
					</span>
				</v-col>
				<v-col
					class="modal-value"
					cols="8"
				>
					<Dropdown
						v-model="scanToCompareWith"
						label="Compare with"
						:items="dropdownItems"
					/>
				</v-col>
				<v-col
					class="pt-4 mt-1"
					cols="1"
				>
					<v-tooltip
						slot="append"
						right
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							Select a completed scan from within this schedule to compare with
						</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</template>

		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Cancel
			</SecondaryActionButton>
			<PrimaryActionButton
				:disabled="scanToCompareWith == null"
				@click="$emit('compare', scanToCompareWith)"
			>
				Compare
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../../shared/components/modal.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'

const UNSUCCESSFUL_SCAN_STATUS_ID = 2

export default {
	components: { PrimaryActionButton, SecondaryActionButton, Modal, Dropdown, TextField },
	props: {
		selectedScan: Object,
		comparableScans: null
	},
	data () {
		return {
			scanToCompareWith: null
		}
	},
	computed: {
		selectedScanLabel () {
			return this.selectedScan.id + ' - ' + this.selectedScan.scanName + ' - ' + this.selectedScan.dateCompleted
		},
		dropdownItems () {
			return this.comparableScans.filter(scan => scan.statusId !== UNSUCCESSFUL_SCAN_STATUS_ID).map(scan => {
				return {
					text: scan.id + ' - ' + scan.scanName + ' - ' + scan.dateCompleted,
					value: scan.id
				}
			})
		}
	}
}
</script>
