
import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getSiteScans = () => {
	return configApi.get('api/SiteScanner').then(({ data }) => {
		return {
			data: {
				singleScans: data.singleScans?.map(scan => {
					return {
						...scan,
						id: scan.id !== null ? scan.id : 'No Number',
						dateRequested: format(new Date(scan.dateRequested), DATE_FORMAT),
						dateCompleted: scan.dateCompleted ? format(new Date(scan.dateCompleted), DATE_FORMAT) : ''
					}
				}),
				recurringScans: data.recurringScans?.map(scan => {
					return {
						...scan,
						dateRequested: format(new Date(scan.dateRequested), DATE_FORMAT),
						dateCompleted: scan.dateCompleted ? format(new Date(scan.dateCompleted), DATE_FORMAT) : '',
						dateRequestedUnformatted: scan.dateRequested
					}
				}),
				lookupData: data.lookupData
			}
		}
	})
}

export const requestScan = scanRequest => configApi.post('api/SiteScanner', scanRequest)
export const requestScheduledScan = scanRequest => configApi.post('api/SiteScanner/CreateSchedule', scanRequest)
export const getScanReport = scanGuid => configApi.get(`api/SiteScanner/Result/${scanGuid}`)
export const updateCookieDetails = updateCookieDetailsRequest => configApi.put('api/SiteScanner/UpdateCookieDetails', updateCookieDetailsRequest)

export const downloadScanFile = (scanGuid, downloadType, cookieName) => {
	const downloadRequest = {
		scanGuid,
		downloadType,
		cookieName
	}

	return configApi.post('api/SiteScanner/Download', downloadRequest)
}

export const deleteCookieScan = scanGuid => configApi.delete(`api/SiteScanner/${scanGuid}`)

export const cancelRecurringScan = groupedSchedulesId => configApi.post(`api/SiteScanner/cancelRecurringScan/${groupedSchedulesId}`)

export const getScanResultsComparison = compareRequest => configApi.post('api/SiteScanner/CompareResults', compareRequest)
