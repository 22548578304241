export default {
	methods: {
		generatePdf (fileContents, fileName) {
			const binaryString = window.atob(fileContents)

			const binaryLen = binaryString.length

			const bytes = new Uint8Array(binaryLen)

			for (let i = 0; i < binaryLen; i++) {
				const ascii = binaryString.charCodeAt(i)
				bytes[i] = ascii
			}

			const blob = new Blob([bytes], { type: 'application/pdf' })
			const url = window.URL.createObjectURL(blob)

			const pom = document.createElement('a')
			pom.href = url
			pom.setAttribute('download', fileName)
			pom.click()
		}
	}
}
