<template>
	<Modal width="850px">
		<template #modal-title>
			Unsuccessful Scan Details - {{ selectedScan.scanName }}
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<v-row dense>
				<v-col cols="12">
					<DataTable
						v-if="selectedScan.errorMessages.length > 0"
						:headers="tableHeaders"
						:items="selectedScan.errorMessages"
						single-expand
						show-expand
					>
						<template #item.url="{ item }">
							{{ getTruncatedText(item.url, 50) }}
						</template>
						<template #item.errorMessage="{ item }">
							{{ getTruncatedText(item.errorMessage, 50) }}
						</template>
						<template #expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<div class="my-5">
									<v-row dense>
										<v-col cols="12">
											<TextField
												label="URL"
												:value="item.url"
												readonly
											/>
										</v-col>
									</v-row>
									<v-row dense>
										<v-col cols="12">
											<Textarea
												label="Error"
												:value="item.errorMessage"
												readonly
											/>
										</v-col>
									</v-row>
								</div>
							</td>
						</template>
					</DataTable>
					<div
						v-else
						class="text-center"
					>
						An error has occurred while processing your request.  Please try again.
					</div>
				</v-col>
			</v-row>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				close
			</SecondaryActionButton>
			<v-spacer />
		</template>
	</Modal>
</template>
<script>
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { getTruncatedText } from '../../../../../../shared/utils/utils.js'
export default {
	components: {
		SecondaryActionButton,
		Modal,
		DataTable,
		TextField,
		Textarea,
		IconButton
	},
	props: {
		selectedScan: Object
	},
	setup () {
		return {
			getTruncatedText
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'URL', value: 'url', width: '50%' },
				{ text: 'Error', value: 'errorMessage', width: '50%' },
				{ text: '', value: 'data-table-expand' }
			]
		}
	}
}
</script>
