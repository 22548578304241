export default {
	methods: {
		generateCsv (fileContents, fileName) {
			const blob = new Blob([fileContents], { type: 'text/csv;charset=utf-8;' })
			const url = URL.createObjectURL(blob)

			const pom = document.createElement('a')
			pom.href = url
			pom.setAttribute('download', fileName)
			pom.click()
		}
	}
}
