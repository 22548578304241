<template>
	<Modal
		width="80%"
		min-width="850px"
		max-height="400px"
	>
		<template #modal-title>
			Cookie Comparison
		</template>
		<template #modal-content>
			<div
				v-if="isLoading || (tableData != null && tableData.length != 0)"
				class="cassie-vertical-md"
			>
				<div class="cassie-vertical-md">
					<p>
						This comparison will identify Cookies which were not present in both Scans.
						<br>
						{{ comparedScansText }}
					</p>
				</div>
				<FilterBar
					v-if="tableData && tableData.length > 0"
					:hide-brand-filter="true"
					:hide-search-query-filter="true"
					@clearFilters="clearFilters"
				>
					<template #after-filters>
						<Dropdown
							v-model="selectedCookieScan"
							label="Filter by Status"
							class="mb-2"
							:items="filterItems"
						/>
					</template>
				</FilterBar>
				<SectionCard v-if="!isLoading">
					<template #body>
						<DataTable
							v-if="tableData && tableData.length > 0"
							:headers="tableHeaders"
							:items="filteredTableData"
							@click:row="downloadReport"
						/>
					</template>
				</SectionCard>
			</div>
			<div v-else>
				<div class="cassie-vertical-md">
					The comparison found no differences in the number of Cookies found on each scan.
				</div>
			</div>
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../../../shared/utils/date-formatting.js'
import Modal from '../../../../../../shared/components/modal.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import { getScanResultsComparison, downloadScanFile } from '../../../../../../shared/utils/api/site-scanner.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { downloadTypeEnum } from './download-type-enums.js'
import downloadCSVMixin from '../../../../../../shared/mixins/download-csv.js'

export default {
	components: { Modal, SectionCard, SecondaryActionButton, DataTable, FilterBar, Dropdown },
	mixins: [downloadCSVMixin],
	props: {
		scanToCompareFrom: String,
		scanToCompareTo: String
	},
	setup () {
		return {
			format,
			DATE_FORMAT,
			downloadTypeEnum
		}
	},
	data () {
		return {
			tableData: null,
			scanFrom: null,
			scanTo: null,
			tableHeaders: [
				{ text: 'Status', value: 'status' },
				{ text: 'Name', value: 'name' },
				{ text: 'Category', value: 'category' },
				{ text: 'Classification', value: 'classification' },
				{ text: 'Domain', value: 'domain' }
			],
			selectedCookieScan: null,
			isLoading: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		comparedScansText () {
			return this.scanFrom.id + ' - ' + this.scanFrom.scanName + ' - ' + this.scanFrom.dateCompleted +
				' & ' + this.scanTo.id + ' - ' + this.scanTo.scanName + ' - ' + this.scanTo.dateCompleted
		},
		filterItems () {
			return [
				{ text: 'Found in Scan ID' + this.scanFrom.id, value: this.scanFrom.scanGuid },
				{ text: 'Found in Scan ID' + this.scanTo.id, value: this.scanTo.scanGuid }
			]
		},
		filteredTableData () {
			const filteredData = this.selectedCookieScan != null ? this.tableData.filter(x => x.guid === this.selectedCookieScan) : this.tableData
			return filteredData
		}
	},
	created () {
		this.scanFrom = JSON.parse(this.scanToCompareFrom)
		this.scanTo = JSON.parse(this.scanToCompareTo)
		this.getComparisonData()
	},
	methods: {
		async getComparisonData () {
			this.isLoading = true
			const compareRequest = {
				scanToCompareFrom: this.scanFrom.scanGuid,
				scanToCompareTo: this.scanTo.scanGuid
			}
			const response = await getScanResultsComparison(compareRequest)
			this.tableData = response.data.cookies.map(cookie => {
				return {
					status: cookie.scanGuid.toLowerCase() === this.scanFrom.scanGuid.toLowerCase() ? 'Found in Scan ID' + this.scanFrom.id : 'Found in Scan ID' + this.scanTo.id,
					name: cookie.name,
					category: cookie.category,
					classification: cookie.classification,
					domain: cookie.domain,
					guid: cookie.scanGuid
				}
			})
			this.isLoading = false
		},
		clearFilters () {
			this.selectedCookieScan = null
		},
		async downloadReport (cookie) {
			const response = await downloadScanFile(cookie.guid, downloadTypeEnum.COOKIE_REPORT, cookie.name)
			const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
			this.generateCsv(response.data, `${cookie.name} - ${fileName}`)
		}
	}
}
</script>
