var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"width":"850px"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Unsuccessful Scan Details - "+_vm._s(_vm.selectedScan.scanName)+" "),_c('v-spacer'),_c('IconButton',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" mdi-close ")])]},proxy:true},{key:"modal-content",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectedScan.errorMessages.length > 0)?_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.selectedScan.errorMessages,"single-expand":"","show-expand":""},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTruncatedText(item.url, 50))+" ")]}},{key:"item.errorMessage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTruncatedText(item.errorMessage, 50))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"my-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TextField',{attrs:{"label":"URL","value":item.url,"readonly":""}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Textarea',{attrs:{"label":"Error","value":item.errorMessage,"readonly":""}})],1)],1)],1)])]}}],null,false,2623615205)}):_c('div',{staticClass:"text-center"},[_vm._v(" An error has occurred while processing your request. Please try again. ")])],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('SecondaryActionButton',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" close ")]),_c('v-spacer')]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }